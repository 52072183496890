<template>
    <div id="app">
        <Start v-if="showStart" @start="showStart = !showStart"/>
        <Finder v-if="!showStart && !finished"/>
        <Finish v-if="finished" @restart="setUp"/>
    </div>
</template>

<script>
import Start from './components/Start.vue'
import Finder from './components/Finder.vue'
import Finish from './components/Finish.vue'
import {items} from './config'

export default {
    name: 'App',
    components: {
        Finder,
        Start,
        Finish
    },
    data() {
        return {
            showStart: true
        };
    },
    computed: {
        finished() {
            return this.$store.getters.getFinish;
        }
    },
    mounted() {
        this.setUp();
    },
    methods: {
        setUp() {
            this.$store.dispatch('initPool', items);
            this.showStart = true;
        }
    }
}
</script>

<style lang="scss">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
    width: 100%;
}

a {
    text-decoration: none;
}

.btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    padding: 1em;
    margin: .5em;
    font-size: 1em;
    color: #fff;
    background-color: #007bff;
    border: 0;
    transition: background-color .15s ease-in-out;
    cursor: pointer;

    &:hover {
        color: #fff;
        background-color: darken(#007bff, 10%);
    }
}
</style>
