<template>
    <div class="container">
        <h2>Ihr Ergebnis</h2>
        <div class="description">
            <h3>Anleitung</h3>
            <p>
                Bitte ermitteln Sie nun aus den gewählten 10 wichtigsten Werten die Sie derzeit in Ihrem Leben sehen, die 3 allerwichtigsten Werte.
                Schreiben Sie die Werte nun untereinander.
                z.B. (hier frei zur Demonstration gewählt)
            </p>
            <ol>
                <li>Gesundheit</li>
                <li>Familie</li>
                <li>Bildung</li>
            </ol>
            <h3>Beantworten Sie sich nun die folgenden Fragen einzeln für jedem Wert:</h3>
            <p>
                Warum ist dieser Wert für mich wichtig?
            </p>
            <p>
                Gehen Sie zu einer Begebenheit in Ihrem Leben, wo dieser Wert vorhanden war, wo Sie diesen Wert gelebt haben. Wie haben Sie sich verhalten, um diesen Wert zu stützen?
            </p>
            <p>
                Wie fühlen Sie, wenn der für Sie wichtige Wert durch andere Personen missachtet wird? Beschreiben sie bitte Ihre Gefühle. Was denken Sie? Wie sehen Ihre Handlungen aus?
            </p>
        </div>
        <div class="result-list">
            <div v-for="item in resultItems" :key="item.name" class="result">
                <b>{{ item.name }}</b>
                <div>
                    <select class="result-select" name="priority[]" v-model="priority[item.name]">
                        <option value="0" disabled selected>Priorität wählen</option>
                        <option v-for="option in options" :key="option.value" :value="option.value" :disabled="option.disabled">{{ option.value }}</option>
                    </select>
                </div>
            </div>
            <div class="reset-button" @click="resetOptions">Prioritäten zurücksetzen</div>
        </div>
        <a class="btn" :href="'mailto:'+mailTo+'?subject='+mailSubject+'&body='+mailBody">Ergebnis absenden</a>
        <button class="btn" @click="$emit('restart')">Erneut versuchen</button>
    </div>
</template>

<script>
import {mailTo, mailSubject} from '@/config'

export default {
    name: 'Finish',
    data: () => {
        return {
            priority: {},
            mailBody: '',
            options: [
                { value: 1, disabled: false },
                { value: 2, disabled: false },
                { value: 3, disabled: false },
                { value: 4, disabled: false },
                { value: 5, disabled: false },
                { value: 6, disabled: false },
                { value: 7, disabled: false },
                { value: 8, disabled: false },
                { value: 9, disabled: false },
                { value: 10, disabled: false }
            ]
        }
    },
    computed: {
        resultItems() {
            return this.$store.getters.getResult;
        },
        mailTo() {
            return mailTo;
        },
        mailSubject() {
            return mailSubject;
        }
    },
    watch: {
        priority: {
            deep: true,
            handler() {
                this.updateMailBody();
                const options = this.options;
                this.options.forEach((option, i) => {
                    options[i].disabled = Object.values(this.priority).findIndex(priority => option.value == priority) > -1
                })
                this.options = options;
            }
        }
    },
    methods: {
        updateMailBody() {
            this.mailBody = this.resultItems.map(item => {
                const prio = (this.priority[item.name] !== 0) ? this.priority[item.name] : 'Keine%20Priorität';
                return item.name + '%20-%20' + prio;
            }).join('%2c%20');
        }
        ,
        resetOptions() {
            this.setDefaults()
        },
        setDefaults() {
            let priority = this.priority;
            this.resultItems.forEach(result => {
                priority[result.name] = 0;
            })
            this.priority = { ...priority };
        }
    }
    ,
    mounted() {
        this.updateMailBody();
        this.setDefaults();
    }
}
</script>

<style scoped lang='scss'>
.container {
    margin: auto auto;
    max-width: 1140px;
}

.result-list {
    max-width: 500px;
    font-size: .9em;
    margin: 2em auto;
}

.result {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
    border-bottom: 1px solid #f3f3f3;
}

.result-select {
    line-height: 20px;
    padding: 8px 10px;
    margin-left: 10px;
    border-color: #ddd;
    background: white;
}

.reset-button {
    text-align: right;
    margin: 1em 0 4em 0;
    cursor: pointer;
    color: #007bff;
}

.description {
    text-align: left;
    margin: 4em 0;
    font-size: 0.9em;
}
</style>
