<template>
    <div class="hello">
        <h1>Willkommen zum Wertefinder!</h1>
        <p>
            Unsere persönlichen Werte leiten uns im Leben. Kennt man seine Werte, so versteht man sich selbst besser. Warum handeln wir in manchen Situationen so wie wir handeln? Welche Werte
            beeinflussen unser Handeln?
        </p>
        <p>
            Und warum reagieren wir manchmal in einer ganz bestimmten Art und Weise, wenn unsere persönlichen Werte nicht verfüllt sind?
        </p>

        <button class="btn" @click="$emit('start')">Werte finden!</button>
    </div>
</template>

<script>
export default {
    name: 'Start'
}
</script>

<style scoped lang='scss'>
.hello {
    margin: auto auto;
    max-width: 1140px;
}

.description {
    text-align: left;
    margin-top: 4em;
    font-size: 0.9em;
}
</style>
