import Vue from 'vue'
import Vuex from 'vuex'
import {amount, finishAmount} from "@/config";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        pool: [],
        round: 2
    },
    getters: {
        getResult(state) {
            return state.pool.filter(item => item.finalized);
        },
        getFinish(state) {
            return state.pool.filter(item => item.finalized).length === finishAmount;

        },
        getAvailablePool(state) {
            let pool = state.pool;
            if (pool.length > 0 && state.round > 2) {
                const finalizedItems = pool.filter(item => item.finalized);
                const openItems      = pool.filter(item => !item.finalized).sort((a, b) => b.upvotes - a.upvotes);
                const maxUpvotes     = openItems[0].upvotes;

                // take maxvoted items from open running round and takeovers from previous round
                const openMaxUpvoted = openItems.filter(item => item.upvotes === maxUpvotes && (item.views + 1 === state.round || item.views + 2 === state.round));

                // if result + max voted items count is below finish amount take next best items for next round, else take only max voted items for next round
                if ((finalizedItems.length + openMaxUpvoted.length) <= finishAmount) {
                    pool = openItems.filter(item => item.upvotes === (maxUpvotes - 1))
                } else {
                    pool = openMaxUpvoted;
                }
            }
            return pool.filter(item => item.views < state.round);
        },
        getItems(state, getters) {
            return getters.getAvailablePool
                .sort(() => 0.5 - Math.random())
                .sort((a, b) => {
                    return a.views < b.views ? -1 : a.views > b.views ? 1 : 0
                })
                .slice(0, amount);
        }
    },
    mutations: {
        clearPool(state) {
            state.pool  = []
            state.round = 2;
        },
        addToPool(state, item) {
            state.pool.push({
                name: item,
                upvotes: 0,
                views: 0,
                finalized: false
            })
        },
        selectItem(state, { selected, items }) {
            const pool = state.pool;

            if (selected) {
                const selectedIndex = state.pool.findIndex(i => i === selected);
                pool[selectedIndex].upvotes += 1;
            }

            items.forEach(item => {
                const outVoteIndex = state.pool.findIndex(i => i === item);
                pool[outVoteIndex].views += 1;
            })

            state.pool = pool;
        },
        increaseRound(state) {
            const pool           = state.pool;
            const finalizedItems = pool.filter(item => item.finalized);
            const openItems      = pool.filter(item => !item.finalized).sort((a, b) => b.upvotes - a.upvotes);
            const maxUpvotes     = openItems[0].upvotes;
            const openMaxUpvoted = openItems.filter(item => item.upvotes === maxUpvotes);

            // set max voted items as result if result + max voted items count is below finish amount
            if ((finalizedItems.length + openMaxUpvoted.length) <= finishAmount) {
                openMaxUpvoted.forEach(item => {
                    const index           = state.pool.findIndex(i => i === item);
                    pool[index].finalized = true;
                })
            }

            // check for possible luckyWinner:
            // take one random item if there are two possible last winners out of max upvoted items or
            // take one random item if there are two possible last winners of next round items
            let luckyWinner = null;
            if ((finalizedItems.length + openMaxUpvoted.length) === finishAmount + 1) {
                luckyWinner = openMaxUpvoted[Math.round(Math.random())];
            }
            const nextItems = openItems.filter(item => item.upvotes === (maxUpvotes - 1))
            if ((finalizedItems.length + openMaxUpvoted.length) === finishAmount - 1 && nextItems.length === 2) {
                luckyWinner = nextItems[Math.round(Math.random())];
            }
            if (luckyWinner) {
                const index           = state.pool.findIndex(i => i === luckyWinner);
                pool[index].finalized = true;
            }

            state.pool = pool;
            state.round += 1;
        }
    },
    actions: {
        initPool(context, items) {
            context.commit('clearPool');
            items.forEach(item => context.commit('addToPool', item))
        },
        selectItem({ commit, getters }, data) {
            commit('selectItem', data)

            if (getters.getItems.length < amount) {
                commit('increaseRound')
            }
        }
    },
    modules: {}
})
