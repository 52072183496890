export const amount       = 3;
export const finishAmount = 10;
export const timerSeconds = 10;
export const mailTo       = 'SSima@sachen-machen.at'
export const mailSubject  = 'Wertefinder Ergebnis'

export const testItems = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U'
];

export const items = [
    'An zukünftige Generationen denken',
    'Anpassungsfähigkeit',
    'Ansehen',
    'Arbeitsplatzsicherheit',
    'Aufgeschlossenheit',
    'Balance zw. Berufs- und Privatleben',
    'Begeisterungsfähigkeit',
    'Beharrlichkeit',
    'Belohnung',
    'Berufliche Weiterentwicklung',
    'Bescheidenheit',
    'Beziehung',
    'Dankbarkeit',
    'Demut',
    'Die/der Beste sein',
    'Disziplin',
    'Effizienz',
    'Ehrgeiz',
    'Ehrlichkeit',
    'Eigenverantwortung',
    'Einfachheit',
    'Einsatzbereitschaft/ Engagement',
    'Entscheidungsfreude',
    'Entspannung',
    'Entwicklung',
    'Erfolg',
    'Erotik',
    'Exzellenz/ Herausragendes leisten',
    'Fairness',
    'Familie',
    'Sicherheit',
    'Fitness',
    'Fleiß',
    'Flexibilität',
    'Freiheit',
    'Freude',
    'Freundschaft',
    'Frieden',
    'Fröhlichkeit',
    'Führen',
    'Fürsorglichkeit',
    'Geduld',
    'Gelassenheit',
    'Gemocht werden',
    'Gemütlichkeit',
    'Gerechtigkeit',
    'Gesundheit',
    'Glaube',
    'Großzügigkeit',
    'Güte',
    'Harmonie',
    'Hilfsbereitschaft',
    'Hoffnung',
    'Höflichkeit',
    'Humor/Spaß',
    'Individualität',
    'Initiative',
    'Interesse/Neugierde',
    'Karriere',
    'Klarheit',
    'Klugheit',
    'Kompetenz',
    'Konfliktbewältigung',
    'Konsequenz',
    'Kontinuierliches Lernen',
    'Kontrolle',
    'Kooperation',
    'Kreativität',
    'Lebensfreude',
    'Lebensstandard',
    'Leichtigkeit',
    'Leidenschaft',
    'Leistungsorientierung',
    'Liebe',
    'Loyalität',
    'Macht',
    'Mitgefühl',
    'Mobilität',
    'Motivation',
    'Motivation',
    'Mut',
    'Nachhaltigkeit',
    'Nächstenliebe',
    'Aufenthalt in der Natur',
    'Offenheit',
    'Optimismus',
    'Ordnungssinn',
    'Partnerschaft',
    'Persönliches Erscheinungsbild',
    'Persönlichkeitsentwicklung',
    'Pflichtgefühl',
    'Phantasie',
    'Positive Grundhaltung',
    'Professionalität',
    'Pünktlichkeit',
    'Bodenständigkeit',
    'Reichtum',
    'Respekt',
    'Risikobereitschaft',
    'Romantik',
    'Rücksichtnahme',
    'Ruhe',
    'Sauberkeit',
    'Selbständigkeit',
    'Selbstbestimmtheit',
    'Selbstdisziplin',
    'Selbstvertrauen',
    'Selbstverwirklichung',
    'Sensibilität',
    'Sicherheit',
    'Sorgfalt',
    'Soziales Engagement',
    'Sparsamkeit',
    'Sport',
    'Standfestigkeit',
    'Status',
    'Tapferkeit',
    'Teamarbeit',
    'Teilen',
    'Tierliebe',
    'Toleranz',
    'Tradition',
    'Treue',
    'Tüchtigkeit',
    'Umweltbewusstsein',
    'Unabhängigkeit',
    'Unbestechlichkeit',
    'Unternehmergeist',
    'Verantwortung',
    'Verlässlichkeit',
    'Vertrauen',
    'Verzeihung/Vergebung',
    'Ziele im Leben haben',
    'Vorsicht',
    'Wachsamkeit',
    'Weitsicht',
    'Wertschätzung',
    'Willenskraft',
    'Wohlbefinden körperlich und emotional',
    'Wohlstand',
    'Work-Life-Balance',
    'Zielstrebigkeit',
    'Zuhören',
    'Zuneigung',
    'Zuverlässigkeit',
    'Zuversicht'
];
