<template>
    <div>
        <h2>Wählen Sie eine Eigenschaft</h2>
        <div class='finder'>
            <div v-for='item in items'
                 :key='item.name'
                 :class="'finder__item bg-' + color"
                 @click='selectItem(item)'
            >{{ item.name }}
            </div>
        </div>
        <div class='timer' :if='timerSeconds > 0'>
            {{ timer }}
        </div>
    </div>
</template>

<script>
import {timerSeconds} from '@/config'

export default {
    name: 'Finder',
    data() {
        return {
            colors: [
                'a5aa94', 'efe3d3', 'f1cdb7', 'ef9d87', 'e2eadf', '99c5c8', 'c4e5ea', 'ab928e', 'e2a374', 'b8cad4', 'dabea6', '88aa81', 'e5bdc5', 'fee1dd', 'c0cfb2', '91ddea', 'bd9dde', '9f89cd', 'd9c961', '8bbc82'
            ],
            color: 'a5aa94',
            timer: 0,
            interval: null
        };
    },
    computed: {
        items() {
            return this.$store.getters.getItems;
        },
        timerSeconds() {
            if(this.round === 2) {
                return timerSeconds * 1.5
            }
            return timerSeconds;
        },
        round() {
            return this.$store.state.round;
        },
    },
    mounted() {
        this.startCountdown(this.timerSeconds);
    },
    methods: {
        selectItem(item) {
            this.$store.dispatch('selectItem', { selected: item, items: this.items });
            this.color = this.pickColor();
            this.startCountdown(this.timerSeconds);
        },
        startCountdown(value) {
            clearInterval(this.interval);
            this.timer = value;
            if (this.timer <= 0) {
                return;
            }
            this.interval = setInterval(() => {
                if (this.timer === 0) {
                    this.selectItem(null);
                }
                this.timer--;
            }, 1000);
        },
        pickColor() {
            const color = this.colors[Math.floor(Math.random() * this.colors.length)];
            if (color === this.color) {
                return this.pickColor();
            }
            return color;
        }
    },
    beforeDestroy() {
        clearInterval(this.interval)
    }
}
</script>

<style scoped lang='scss'>
.finder {
    $colors: 'a5aa94', 'efe3d3', 'f1cdb7', 'ef9d87', 'e2eadf', '99c5c8', 'c4e5ea', 'ab928e', 'e2a374', 'b8cad4', 'dabea6', '88aa81', 'e5bdc5', 'fee1dd', 'c0cfb2', '91ddea', 'bd9dde', '9f89cd', 'd9c961', '8bbc82';
    display: flex;
    flex-wrap: wrap;

    &__item {
        background-color: #b1aeae;
        border-radius: 5px;
        cursor: pointer;
        width: 100%;
        margin: 1em;
        padding: 3em;
        transition: .2s background-color;

        @media (min-width: 768px) {
            padding: 5em;
            flex-basis: 0;
            flex-grow: 1;
        }

        @each $c in $colors {
            &.bg-#{$c} {
                $color: #{'#' + $c};
                background-color: $color;

                &:hover {
                     filter: brightness(90%);
                }
            }
        }
    }
}

.timer {
    margin: 2em;
    font-size: 2em;
}
</style>
